import React from 'react';
import { withStyles, Tooltip, makeStyles } from '@material-ui/core';

// const StyledTooltip = withStyles((theme, maxWidth) => ({
//   tooltip: {
//     backgroundColor: theme.palette.secondary.main,
//     fontSize: 14,
//     fontWeight: 700,
//     borderRadius: 5,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     maxWidth: maxWidth,
//     // maxHeight: '50px'
//   },
//   arrow: {
//     color: theme.palette.secondary.main,
//   },
// }))(Tooltip);

// export default StyledTooltip;
const useStyles = makeStyles((theme) => ({
  tooltip: ({ maxWidth, backgroundColor, color }) => ({
    backgroundColor: backgroundColor ? backgroundColor : theme.palette.secondary.main,
    color: color ? color : '#ffffff',
    fontSize: 14,
    fontWeight: 700,
    borderRadius: 5,
    padding: theme.spacing(1),
    textAlign: 'center',
    maxWidth: maxWidth ? maxWidth : 300,
    // maxWidth: maxWidth ? maxWidth : 320,
  }),
  arrow: {
    color: theme.palette.secondary.main,
  },
}));
export default function StyledTooltip({ tooltipStyle, ...props }) {
  const classes = useStyles(tooltipStyle);
  return (
    <Tooltip {...props} classes={classes} />
  );
}