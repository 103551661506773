import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import Tooltip from 'src/components/tooltip';

import TooltipIcon from 'src/assets/icons/tooltip.svg';

const useStyles = makeStyles(theme => ({
  root: ({ gutterBottom, position }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: position,
    marginBottom: theme.spacing(gutterBottom),
  }),
  icon: {
    // paddingLeft: 9,
    marginLeft: 9,
    display: 'flex',
  },
}));

export default function TooltipedText({ children, tooltipText, gutterBottom, rootStyle, position, style, tooltipStyle, iconStyle }) {
  const classes = useStyles({ gutterBottom, position });

  return (
    <span className={classes.root} style={rootStyle}>
      {
        style
          ? <span style={style}>{children}</span>
          : <>{children}</>
      }

      <Tooltip title={tooltipText} arrow tooltipStyle={tooltipStyle}>
        <span className={classes.icon} style={iconStyle}>
          <TooltipIcon />
        </span>
      </Tooltip>
    </span>
  );
}

TooltipedText.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipText: PropTypes.string.isRequired,
  gutterBottom: PropTypes.number,
  position: PropTypes.string,
};

TooltipedText.defaultProps = {
  gutterBottom: 0,
  position: 'unset',
};
